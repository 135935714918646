<template>
  <div class="emailResetPassword">
    <EmailResetPasswordComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import EmailResetPasswordComponent from '@/components/auth/EmailResetPasswordComponent.vue'

export default {
  name: 'EmailResetPasswordView',
  components: {
    EmailResetPasswordComponent
  }
}
</script>
